<template>
  <div id="design-section-template">

    <LetsChat></LetsChat>

    <section id="hero-logo">
      <div class="hero-logo_upper-section" :style="currGradientStyle">
        <img src="../assets/design/design-top.png" alt="design-logo" width="30%">
      </div>
      <div class="hero-logo_lower-section">
        <img src="../assets/design/design-down.png" alt="design-logo" width="30%">
      </div>
    </section>

    <section id="design-carousel">

      <div class="design-carousel_left-half" :style="{ backgroundColor: weDesignBgColor }">
        <aside class="text-block">
          <h2>We Design</h2>
          <p>As designers we understand the importance of communicating brand messaging with seamless functionality and a beautiful visual impact. Explore some of our recent projects to see how we’ve been delighting customers with exceptional user experiences.</p>
        </aside>
      </div>

      <div class="design-carousel_right-half">

        <div id="design-carousel-content">

          <div id="slides">
            <VueSlickCarousel
                :arrows="false"
                :dots="false"
                v-bind="designSlickOptions"
                ref="carouselSlider"
                @afterChange="sliderChangeHandler($event)"
            >

              <div v-for="slider in designSlider" :key="slider.id" class="slider">
                <ul>
                  <router-link :to="slider.name" exact>
                    <li class="current">
                      <img :src="slider.designPic"/>
                      <button class="specialButton" type="button">View case study</button>
                    </li>
                  </router-link>
                </ul>
              </div>

            </VueSlickCarousel>
          </div>

          <div class="design-carousel-content__arrows">
            <button class="prev-carousel-buttons" @click="showPrevSlider()">
              <img :src="leftBlue" class="next-slider" alt="leftArrowDesign">
            </button>

            <button class="next-carousel-buttons" @click="showNextSlider()">
              <img :src="rightBlue" class="prev-slider" alt="rightArrowDesign">
            </button>
          </div>

        </div>

      </div>

    </section>

<!--    <section class="map-hac bg-gray-hac design-map">-->
<!--      <section id="scr-custom-map-design">-->
<!--        <div class="scr-custom-map-design" data-parallaxify-range-x="300" data-parallaxify-range-y="350"></div>-->
<!--      </section>-->
<!--    </section>-->

    <ContactUs></ContactUs>

    <Footer color="#181818"></Footer>

  </div>
</template>

<script>
import $ from 'jquery'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ContactUs from "../components/ContactUs";
import Footer from "../components/Home/Footer";

//design carousel pic
import avigora from '@/assets/design-carousel/avigora-preview.jpg';
import careful from '@/assets/design-carousel/careful-preview.jpg';
import ciutacu from '@/assets/design-carousel/ciutacu-preview.jpg';
import creeds from '@/assets/design-carousel/creeds-preview.jpg';
import excape from '@/assets/design-carousel/excape-preview.jpg';

//design arrows
import leftArrowBlue from '@/assets/design-carousel/left-long-arrow-blue.svg';
import rightArrowBlue from '@/assets/design-carousel/right-long-arrow-blue.svg';
import LetsChat from "../components/LetsChat";

export default {

  name: "Design",

  components: {LetsChat, Footer, ContactUs, VueSlickCarousel},

  data() {
    return {
      leftBlue: leftArrowBlue,
      rightBlue: rightArrowBlue,
      step: 0,
      currIdx: 0,
      nextIdx: 1,
      totalSteps: 228,
      rgbValues: {start: [0, 0, 0], stop: [0, 0, 0]}, // values for gradient
      rgbSteps: {start: [0, 0, 0], stop: [0, 0, 0]}, // steps
      col1: null,
      col2: null,
      currGradientStyle: {
        backgroundImage: null
      },
      colors: [
        {
          start: [62, 35, 255], stop: [60, 255, 60]
        },
        {
          start: [255, 35, 984], stop: [45, 175, 230]
        },
        {
          start: [255, 0, 255], stop: [255, 128, 0]
        }
      ],
      designSlider: [
        {
          id: 0,
          designPic: avigora,
          name: '/avigora',
          colorClass: 'bck-orange',
          backColor: '#ed6a26',
        },
        {
          id: 1,
          designPic: careful,
          name: '/careful',
          colorClass: 'bck-dust',
          backColor: '#b79685',

        },
        {
          id: 2,
          designPic: ciutacu,
          name: '/ciutacu',
          colorClass: 'bck-red',
          backColor: '#dc3522',
        },
        {
          id: 3,
          designPic: creeds,
          name: '/creeds',
          colorClass: 'bck-grey',
          backColor: '#46585e',
        },
        {
          id: 4,
          designPic: excape,
          name: '/excape',
          colorClass: 'bck-yellow',
          backColor: '#dda427',
        },
      ],
      designSlickOptions: {
        slidesToShow: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        adaptiveHeight: false,
        draggable: true,
        swipe: true,
        arrows: false,
        dots: false,
        prevArrow: $('.prev-slider'),
        nextArrow: $('.next-slider'),
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
      weDesignBgColor: 'orange',
    }
  },

  mounted() {
    $(document).ready(function () {
      //rotation speed and timer
      var speed = 6000;
      var run = setInterval('this.rotate', speed);

      //grab the width and calculate left value
      var item_width = $('#slides li').outerWidth();
      var left_value = item_width * (-1);
      $('.design-carousel_left-half').css('background-color', '#ed6a26');
      //move the last item before first item, just in case user click prev button
      $('#slides li:first').before($('#slides li:last'));
      $("ul li:nth-child(2)").addClass('current');
      //set the default item to the correct position
      $('#slides ul').css({'left': left_value});

      //if user clicked on prev button
      $('').click(function () {
        $("ul li").removeClass('current');
        $("ul li:nth-child(1)").addClass('current');

        if ($('.current').hasClass('bck-blue')) {
          $('.design-carousel_left-half').css('background-color', '#2689c0');
        } else if ($('.current').hasClass('bck-red')) {
          $('.design-carousel_left-half').css('background-color', '#dc3522');
        } else if ($('.current').hasClass('bck-yellow')) {
          $('.design-carousel_left-half').css('background-color', '#dda427');
        } else if ($('.current').hasClass('bck-dust')) {
          $('.design-carousel_left-half').css('background-color', '#b79685');
        } else if ($('.current').hasClass('bck-grey')) {
          $('.design-carousel_left-half').css('background-color', '#46585e');
        } else if ($('.current').hasClass('bck-orange')) {
          $('.design-carousel_left-half').css('background-color', '#46585e');
        } else {
          $('.design-carousel_left-half').css('background-color', '#ed6a26');
        }

        //get the right position
        var left_indent = parseInt($('#slides ul').css('left')) + item_width;

        //slide the item
        $('#slides ul').animate({'left': left_indent}, 200, function () {

          //move the last item and put it as first item
          $('#slides li:first').before($('#slides li:last'));

          //set the default item to correct position
          $('#slides ul').css({'left': left_value});

        });

        //cancel the link behavior
        return false;
      });

      //if user clicked on next button
      $('').click(function () {
        $("ul li").removeClass('current');
        $("ul li:nth-child(3)").addClass('current');

        if ($('.current').hasClass('bck-blue')) {
          $('.design-carousel_left-half').css('background-color', '#2689c0');
        } else if ($('.current').hasClass('bck-red')) {
          $('.design-carousel_left-half').css('background-color', '#dc3522');
        } else if ($('.current').hasClass('bck-yellow')) {
          $('.design-carousel_left-half').css('background-color', '#dda427');
        } else if ($('.current').hasClass('bck-dust')) {
          $('.design-carousel_left-half').css('background-color', '#b79685');
        } else if ($('.current').hasClass('bck-grey')) {
          $('.design-carousel_left-half').css('background-color', '#ed6a26');
        } else {
          $('.design-carousel_left-half').css('background-color', '#ed6a26');
        }

        //get the right position
        var left_indent = parseInt($('#slides ul').css('left')) - item_width;

        //slide the item
        $('#slides ul').animate({'left': left_indent}, 600, function () {

          //move the first item and put it as last item
          $('#slides li:last').after($('#slides li:first'));

          //set the default item to correct position
          $('#slides ul').css({'left': left_value});
        });

        //cancel the link behavior
        return false;
      });

      //if mouse hover, pause the auto rotation, otherwise rotate it
      $('#design-carousel-content').hover(
          function () {
            clearInterval(run);
          },
          function () {
            run = setInterval('this.rotate', speed);
          }
      );
    });

  },

  methods: {
    showNextSlider() {
      this.$refs.carouselSlider.next();
    },

    showPrevSlider() {
      this.$refs.carouselSlider.prev();
    },

    rotate: function () {
      $('').click();
      $("ul li").removeClass('current');
      $("ul li:nth-child(3)").addClass('current');

      if ($('.current').hasClass('bck-blue')) {
        $('.design-carousel_left-half').css('background-color', '#2689c0');
      } else if ($('.current').hasClass('bck-red')) {
        $('.design-carousel_left-half').css('background-color', '#dc3522');
      } else if ($('.current').hasClass('bck-yellow')) {
        $('.design-carousel_left-half').css('background-color', '#dda427');
      } else if ($('.current').hasClass('bck-dust')) {
        $('.design-carousel_left-half').css('background-color', '#b79685');
      } else if ($('.current').hasClass('bck-grey')) {
        $('.design-carousel_left-half').css('background-color', '#46585e');
      } else {
        $('.design-carousel_left-half').css('background-color', '#ed6a26');
      }
    },

    nextStep(n) {
      return (n + 1 < this.colors.length) ? n + 1 : 0
    },

    calcStepSize(a, b) {
      return (a - b) / this.totalSteps
    },

    calcSteps() {
      for (let key in this.rgbValues) {
        if (this.rgbValues) {
          for (var i = 0; i < 3; i++) {
            this.rgbValues[key][i] = this.colors[this.currIdx][key][i]
            this.rgbSteps[key][i] = this.calcStepSize(this.colors[this.nextIdx][key][i], this.rgbValues[key][i])
            //console.log(this.rgbSteps[key])
          }
        }
      }
    },
    //////////////////////
    updateGradient() {
      for (let key in this.rgbValues) {
        if (this.rgbValues) {
          for (var i = 0; i < 3; i++) {
            this.rgbValues[key][i] += this.rgbSteps[key][i]
          }
        }
      }

      let tCol1 = "rgb(" + (this.rgbValues.start[0] | 0) + "," + (this.rgbValues.start[1] | 0) + "," + (this.rgbValues.start[2] | 0) + ")"
      let tCol2 = "rgb(" + (this.rgbValues.stop[0] | 0) + "," + (this.rgbValues.stop[1] | 0) + "," + (this.rgbValues.stop[2] | 0) + ")"

      if (tCol1 != this.col1 || tCol2 != this.col2) {
        this.col1 = tCol1
        this.col2 = tCol2
        this.currGradientStyle.backgroundImage = "linear-gradient(45deg, " + this.col1 + ", " + this.col2 + ")"
      }

      this.step++
      if (this.step > this.totalSteps) {
        this.step = 0;

        this.currIdx = this.nextStep(this.currIdx)
        this.nextIdx = this.nextStep(this.nextIdx)

        this.calcSteps()
      }

      if (this.currGradientStyle.backgroundImage.indexOf("gradient") != -1) {
        window.requestAnimationFrame(this.updateGradient)
      }
    },

    sliderChangeHandler(websiteId) {
      switch (websiteId) {
        case 0:
          this.weDesignBgColor = 'red';
          break;
        case 1:
          this.weDesignBgColor = 'green';
          break;
        case 2:
          this.weDesignBgColor = 'blue';
          break;
        case 3:
          this.weDesignBgColor = 'pink';
          break;
        case 4:
          this.weDesignBgColor = 'black';
          break;
        default:
          this.weDesignBgColor = 'red';
          break;
      }
    },

  },

  created() {
    this.calcSteps()
    window.requestAnimationFrame(this.updateGradient);
  }
}

</script>

<style scoped lang="scss">

.design-section-template {
  overflow: hidden;
}

.hero-logo_upper-section {
  width: 100%;
  height: 50vh;
  background-image: linear-gradient(45deg, rgb(53, 77, 116), rgb(84, 125, 157));
  z-index: -1;
  position: relative
}

.hero-logo_upper-section img {
  position: absolute;
  bottom: 0;
  margin: 0 35%
}

.hero-logo_lower-section {
  width: 100%;
  height: 50vh;
  background-color: #f5f5f5;
  position: relative
}

.hero-logo_lower-section img {
  position: absolute;
  top: 0;
  margin: 0 35%
}

#design-carousel {
  height: 100vh
}

#design-carousel .design-carousel_left-half {
  width: 50%;
  float: left;
  height: 100vh;
  transition: background-color 1s;
}

#design-carousel .design-carousel_right-half {
  width: 50%;
  float: left
}

#design-carousel {
  margin: 0 auto;
  display: block;
}

.specialButton a {
  color: #2d98c8;
  font-size: 20px;
  width: 100%;
  display: block;
  text-decoration: none;
}

#design-carousel-content, #slides, #slides li {
  height: 460px;
  width: 100%;
}

.specialButton:hover a {
  color: #fff
}

#design-carousel .design-carousel_left-half .text-block {
  padding: 30% 10%;
  text-align: right;
}

#design-carousel .design-carousel_left-half .text-block h2 {
  font-size: 50px;
  font-family: Lato-Light, sans-serif;
  color: #FFF;
  line-height: 70px
}

#design-carousel .design-carousel_left-half .text-block p {
  font-size: 20px;
  font-family: Lato-Light, sans-serif;
  color: #FFF;
  line-height: 28px
}

#design-carousel-content {
  margin: 90px auto
}

#slides {
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.56);
  box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.56);
}

#slides li {
  float: left
}

.design-carousel-content__arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.prev-carousel-buttons, .next-carousel-buttons {
  padding-top: 30px;
  float: right;
  width: 100%
}

.prev-carousel-buttons img, .next-carousel-buttons img {
  display: block;
  width: 90%;
  height: 40px;
  text-indent: -999em;
  float: left;
  outline: 0
}

.prev-carousel-buttons img:hover,
.next-carousel-buttons img:hover {
  transition: transform .5s;
  transform: scale(1.2);
}

.clear {
  clear: both
}

.map-hac, .bg-gray-hac, .design-map {
  background: #333333;
}

.scr-custom-map-design {
  width: 100%;
  height: 80%;
  margin: 0 auto;
  background: url(../assets/design/map-design.svg) no-repeat;
  background-size: 80%;
  background-position: center;
  border: none;
}

#scr-custom-map-design {
  height: 95vh;
  background-color: #333333;
  border: none;
}

.specialButton {
  display: block;
  margin: 10px auto;
  border-radius: 99px;
  z-index: 1;
  position: relative;
  color: #2d98c8;
  font-size: 17px;
  padding: 12px 45px 12px 45px;
  letter-spacing: 2px;
}

.specialButton:before,
.specialButton:after {
  content: ' ';
  position: absolute;
  border-radius: 99px;

}

.specialButton:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 0%, #2d98c8 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, #ffffff), color-stop(100%, #2d98c8));
  background: -webkit-linear-gradient(left, #ffffff 0%, #2d98c8 100%);
  background: -o-linear-gradient(left, #ffffff 0%, #2d98c8 100%);
  background: -ms-linear-gradient(left, #ffffff 0%, #2d98c8 100%);
  background: linear-gradient(to left, #ffffff -2%, #2d98c8 41%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#2d98c8', GradientType=1);
}

.specialButton:hover {
  color: #fafafa;
  transition: all 0.5s ease;
}

.specialButton:after {
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 0;
  background-color: #ffffff;
  z-index: -1;
  opacity: 1;
  transition: all 0.6s ease-in-out;
}

.specialButton:hover:after {
  opacity: 0;
}

@media (min-width: 1440px) {
  #design-carousel, #design-carousel .design-carousel_left-half {
    height: 85vh
  }
}

@media screen and (max-width: 1024px) {
  .hero-logo_lower-section{
    height:40vh;
    img{
      margin: 0 20%;
      width: 60%;
    }

  }
  .hero-logo_upper-section{
    height:40vh;
    img{
      margin: 0 20%;
      width: 60%;
    }

  }
  #design-carousel .design-carousel_left-half {
    width: 40%;
    float: left;
    height: 100vh;
    transition: background-color 2s
  }
  #design-carousel .design-carousel_right-half {
    width: 60%;
    float: left;
    height: 100vh
  }
  #buttons {
    padding: 30px 0 5px;
    float: right;
    width: 100%
  }
  #design-carousel .design-carousel_left-half {
    width: 100%;
    float: left;
    height: 85vh;
    transition: background-color 2s
  }
  .lower-section img,
  .upper-section img {
    width: 60%;
    margin: 0 20%
  }
  #design-carousel .design-carousel_right-half {
    width: 100%;
    height: 75vh;
    float: none;
    clear: both
  }
  #design-carousel {
    height: 180vh
  }
  #scr-custom-map-design {
    height: 70vh;
  }
}

@media (min-width: 320px) and (max-width: 550px) {
  #carousel,
  #slides {
  width: 100%;
  }

  #design-carousel-content {
    margin: 10% auto
  }

  #slides {
    overflow: hidden;
    position: relative
  }

  #slides ul {
    position: relative;
    left: 0;
    top: 0;
    list-style: none;
    margin: 0;
    padding: 0;

  }



  #buttons,
  .customers-img-mobile img {
    width: 100%
  }

  #buttons {
    padding: 30px 0 5px;
    float: right
  }
}
</style>